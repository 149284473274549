export const links = [
  {
    id: "pages",
    title: "Pages",
    items: [
      { id: "home", title: "Home", href: "/" },
      {
        id: "pricing",
        title: "Pricing",
        href: "/pricing",
      },
      {
        id: "WWOD",
        title: "Free Workout Of The Day",
        href: "/workout/today",
      },
      {
        id: "ladder_shop",
        title: "Ladder Shop",
        href: "https://shop.joinladder.com",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        id: "plan",
        title: "Find Your Plan",
        href: "https://offers.joinladder.com/survey",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        id: "blog",
        title: "Blog",
        href: "https://blog.joinladder.com",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        id: "careers",
        title: "Careers",
        href: "https://ladder.breezy.hr",
        target: "_blank",
        rel: "noreferrer",
      },
    ],
  },
  {
    id: "teams",
    title: "Programs",
    items: [
      {
        title: "Body & Bell",
        href: "https://teams.joinladder.com/program/lauren",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Boundless",
        href: "https://teams.joinladder.com/program/ben-greenfield",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Crews Control",
        href: "https://teams.joinladder.com/program/andre-crews",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "ELEVATE",
        href: "https://teams.joinladder.com/program/elise",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Maximus",
        href: "https://teams.joinladder.com/program/bobby-joe",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Project Alpha",
        href: "https://teams.joinladder.com/program/sam-tooley",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Team Resilient",
        href: "https://teams.joinladder.com/program/reign-neen",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Team RX",
        href: "https://teams.joinladder.com/program/emily-rethwill",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "EMPWR",
        href: "https://teams.joinladder.com/program/courteney-fisher",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Limitless",
        href: "https://teams.joinladder.com/program/kelly-matthews",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Movewell",
        href: "https://teams.joinladder.com/program/nicole-rauch",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Iconic",
        href: "https://teams.joinladder.com/program/sarah-ryan",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Formation",
        href: "https://teams.joinladder.com/program/robin-b",
        target: "_blank",
        rel: "noreferrer",
      },
    ],
  },
];
